"use strict";

app.common.init();
app.menu.init();
app.category.init();
app.questions.init();
app.list.init();
app.form.init();
app.contacts.mapLoading();
app.reviews.init();
app.header.init();
app.popup.init();