'use strict';

app.header = {
	header: document.querySelector('.header'),
	init: function init() {
		this.changePosition();
	},
	changePosition: function changePosition() {
		var _this = this;

		window.addEventListener('scroll', function () {
			if (document.documentElement.scrollTop > 45) {
				_this.header.style = 'background-color: #ffffff;';
			} else {
				_this.header.style = '';
			}
		});
	}
};