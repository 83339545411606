'use strict';

app.popup = {
	init: function init() {
		app.common.addStyleFile('jquery.fancybox.min');
		$('#popup-init').click(function (evt) {
			evt.preventDefault();
			$.fancybox.open({
				src: '#popup-form',
				type: 'inline'
			});
		});
	}
};