'use strict';

app.menu = {
	pageWrapper: document.querySelector('.wrapper'),
	menuContainer: document.querySelector('.menu'),
	menu: document.querySelector('.menu__main'),
	searchInput: document.querySelector('.search__input'),
	closeSearchButton: document.querySelector('.search__back'),
	searchButtonWrapper: document.querySelector('.search__box'),
	searchButtonClear: document.querySelector('.search__clear'),
	searchResultBlock: document.querySelector('.search__result'),
	openMenuButton: document.querySelector('.header__menu'),
	closeMenuButton: document.querySelector('.menu__close'),
	init: function init() {
		this.onSearchActive();
		this.checkSearchValue();
		this.clearSearchInput();
		this.openMenu();
		this.closeMenu();
	},
	onSearchActive: function onSearchActive() {
		var _this = this;

		this.searchInput.addEventListener('focus', function () {
			_this.menu.classList.add('menu__main_search_active');
			_this.closeSearchButton.addEventListener('click', function () {
				_this.menu.classList.remove('menu__main_search_active');
			});
		});
	},
	checkSearchValue: function checkSearchValue() {
		var _this2 = this;

		this.searchInput.addEventListener('keyup', function () {
			if (_this2.searchInput.value.length > 0) {
				_this2.searchButtonWrapper.classList.add('search__box_not-empty');
				_this2.searchResultBlock.classList.add('search__result_show');
			}
			if (_this2.searchInput.value.length === 0) {
				_this2.searchButtonWrapper.classList.remove('search__box_not-empty');
				_this2.searchResultBlock.classList.remove('search__result_show');
			}
		});
	},
	clearSearchInput: function clearSearchInput() {
		var _this3 = this;

		this.searchButtonClear.addEventListener('click', function (evt) {
			evt.preventDefault();
			_this3.searchInput.value = '';
			_this3.searchButtonWrapper.classList.remove('search__box_not-empty');
			_this3.searchResultBlock.classList.remove('search__result_show');
		});
	},
	openMenu: function openMenu() {
		var _this4 = this;

		this.openMenuButton.addEventListener('click', function () {
			_this4.menuContainer.classList.add('menu_open');
			_this4.pageWrapper.classList.add('overlay');
			_this4.openMenuButton.style = 'opacity: 0;';
		});
	},
	closeMenu: function closeMenu() {
		var _this5 = this;

		this.closeMenuButton.addEventListener('click', function () {
			_this5.menuContainer.classList.remove('menu_open');
			_this5.pageWrapper.classList.remove('overlay');
			setTimeout(function () {
				_this5.openMenuButton.style = '';
			}, 150);
		});
	}
};