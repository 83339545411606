'use strict';

/* eslint-disable no-unused-vars */
/* eslint-disable quote-props */
app.contacts = {
	mapContainer: document.querySelector('.map'),
	mapLoading: function mapLoading() {
		if (this.mapContainer) {
			this.initMap();
		}
	},
	initMap: function initMap() {
		var options = {
			coordinates: {
				lat: 53.919420,
				lng: 27.500413
			},
			markerImage: './assets/images/marker.svg',
			styles: [{
				'featureType': 'all',
				'elementType': 'labels.text.fill',
				'stylers': [{
					'saturation': 36
				}, {
					'color': '#333333'
				}, {
					'lightness': 40
				}]
			}, {
				'featureType': 'all',
				'elementType': 'labels.text.stroke',
				'stylers': [{
					'visibility': 'on'
				}, {
					'color': '#ffffff'
				}, {
					'lightness': 16
				}]
			}, {
				'featureType': 'all',
				'elementType': 'labels.icon',
				'stylers': [{
					'visibility': 'off'
				}]
			}, {
				'featureType': 'administrative',
				'elementType': 'geometry.fill',
				'stylers': [{
					'color': '#fefefe'
				}, {
					'lightness': 20
				}]
			}, {
				'featureType': 'administrative',
				'elementType': 'geometry.stroke',
				'stylers': [{
					'color': '#fefefe'
				}, {
					'lightness': 17
				}, {
					'weight': 1.2
				}]
			}, {
				'featureType': 'landscape',
				'elementType': 'geometry',
				'stylers': [{
					'color': '#f5f5f5'
				}, {
					'lightness': 20
				}]
			}, {
				'featureType': 'poi',
				'elementType': 'geometry',
				'stylers': [{
					'color': '#f5f5f5'
				}, {
					'lightness': 21
				}]
			}, {
				'featureType': 'poi.park',
				'elementType': 'geometry',
				'stylers': [{
					'color': '#dedede'
				}, {
					'lightness': 21
				}]
			}, {
				'featureType': 'road.highway',
				'elementType': 'geometry.fill',
				'stylers': [{
					'color': '#ffffff'
				}, {
					'lightness': 17
				}]
			}, {
				'featureType': 'road.highway',
				'elementType': 'geometry.stroke',
				'stylers': [{
					'color': '#ffffff'
				}, {
					'lightness': 29
				}, {
					'weight': 0.2
				}]
			}, {
				'featureType': 'road.arterial',
				'elementType': 'geometry',
				'stylers': [{
					'color': '#ffffff'
				}, {
					'lightness': 18
				}]
			}, {
				'featureType': 'road.local',
				'elementType': 'geometry',
				'stylers': [{
					'color': '#ffffff'
				}, {
					'lightness': 16
				}]
			}, {
				'featureType': 'transit',
				'elementType': 'geometry',
				'stylers': [{
					'color': '#f2f2f2'
				}, {
					'lightness': 19
				}]
			}, {
				'featureType': 'water',
				'elementType': 'geometry',
				'stylers': [{
					'color': '#e9e9e9'
				}, {
					'lightness': 17
				}]
			}]
		};
		var map = new google.maps.Map(this.mapContainer, {
			center: options.coordinates,
			zoom: 15,
			disableDefaultUI: false
		});
		var marker = new google.maps.Marker({
			position: options.coordinates,
			map: map,
			animation: google.maps.Animation.DROP,
			icon: options.markerImage
		});
		map.setOptions({ styles: options.styles });
	}
};