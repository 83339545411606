'use strict';

/* eslint-disable no-new */
app.form = {
	init: function init() {
		this.initMask();
		this.stylingFileInput();
		this.textAreaStyle();
	},
	stylingFileInput: function stylingFileInput() {
		var inputs = document.querySelectorAll('.form__input_file');
		Array.prototype.forEach.call(inputs, function (input) {
			var label = input.nextElementSibling;
			var labelVal = label.innerHTML;
			input.addEventListener('change', function (e) {
				var fileName = '';
				if (this.files && this.files.length > 1) {
					fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
				} else {
					fileName = e.target.value.split('\\').pop();
				}
				if (fileName) {
					label.innerHTML = fileName;
				} else {
					label.innerHTML = labelVal;
				}
			});
		});
	},
	initMask: function initMask() {
		var phoneInputs = document.querySelectorAll('.input_phone');
		phoneInputs.forEach(function (input) {
			new Cleave(input, {
				phone: true,
				phoneRegionCode: 'BY'
			});
		});
	},
	textAreaStyle: function textAreaStyle() {
		var textAreaInput = document.querySelector('.form__input-pseudo');
		var textAreaValue = document.querySelector('.input_textarea');
		if (!textAreaInput) {
			return;
		}
		var onFocus = function onFocus() {
			if (textAreaInput.textContent.length > 0) {
				textAreaInput.removeAttribute('data-placeholder');
			} else {
				textAreaInput.setAttribute('data-placeholder', 'Ваш вопрос');
			}
		};
		textAreaInput.addEventListener('focus', function () {
			textAreaInput.classList.add('form__input-pseudo_focused');
			textAreaInput.addEventListener('keyup', onFocus);
		});
		textAreaInput.addEventListener('blur', function () {
			if (textAreaInput.textContent.length > 0) {
				textAreaValue.value = textAreaInput.textContent;
			}
		});
	}
};