'use strict';

app.reviews = {
	init: function init() {
		var $reviews = $('.reviews__text');
		$reviews.readmore(this.options);
	},

	options: {
		moreLink: '<div class="reviews__button-wrapper"><button class="reviews__toogle">читать дальше</button></div>',
		lessLink: '<div class="reviews__button-wrapper"><button class="reviews__toogle">свернуть</button></div>',
		embedCSS: false,
		afterToggle: function afterToggle(trigger, element) {
			console.log(element[0].classList.toggle('reviews__text_open'));
		}
	}
};