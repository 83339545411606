'use strict';

app.list = {
	block: document.querySelector('.list_others'),
	items: document.querySelectorAll('.list__box_nolink'),
	init: function init() {
		this.toggleBlocks();
	},
	toggleBlocks: function toggleBlocks() {
		if (this.block) {
			this.items.forEach(function (item) {
				item.addEventListener('click', function (evt) {
					console.log(evt);
					evt.target.classList.toggle('list__box_open');
				});
			});
		}
	}
};