'use strict';

app.category = {
	categoryBlock: document.querySelector('.category__wrapper'),
	hoverBlock: document.querySelector('.category__hover-el'),
	item: document.querySelector('.category__item_hover'),
	videoSell: document.querySelector('.category__video_sell'),
	videoBuy: document.querySelector('.category__video_buy'),
	init: function init() {
		if (this.categoryBlock && window.innerWidth > 576) {
			this.onHover();
		}
		if (this.categoryBlock && window.innerWidth < 576) {
			this.item.classList.remove('category__item_hover');
		}
	},
	onHover: function onHover() {
		var _this = this;

		this.categoryBlock.addEventListener('mouseover', function (evt) {
			_this.onLeaveCategoryBlock();
			var target = evt.target.closest('a');
			if (target === null) {
				return;
			}
			if (target !== null && target.dataset.category === 'buy') {
				_this.hoverBlock.classList.add('category__hover-el_move');
				_this.item.classList.remove('category__item_hover');
				console.log(_this.videoSell.currentTime);
				_this.videoSell.play();
			} else if (target !== null && target.dataset.category === 'sell') {
				_this.hoverBlock.classList.remove('category__hover-el_move');
				_this.videoBuy.play();
			}
		});
	},
	onLeaveCategoryBlock: function onLeaveCategoryBlock() {
		var _this2 = this;

		this.categoryBlock.addEventListener('mouseleave', function () {
			setTimeout(function () {
				_this2.item.classList.add('category__item_hover');
				_this2.hoverBlock.classList.remove('category__hover-el_move');
			}, 1000);
		});
	}
};